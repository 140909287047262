import { ReactNode } from "react";

import { cn } from "~/lib/utils";

export function PageContent({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) {
  return (
    <div className={cn("mx-auto w-full max-w-6xl px-2 py-16", className)}>
      {children}
    </div>
  );
}
